import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import classNames from 'classnames';
import { useField } from 'formik';
import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import InputMask from 'react-input-mask';
import EnStyles from './part-textfield.module.scss';
import ArStyles from './part-textfield.ar.module.scss';
import { userService } from '../../../services';
var PartFormTextfield = function PartFormTextfield(props) {
  var _classNames;
  var isArabic = userService.isArabicLang();
  var styles = isArabic ? ArStyles : EnStyles;
  var _React$useState = React.useState(),
    _React$useState2 = _slicedToArray(_React$useState, 2),
    internalValue = _React$useState2[0],
    setInternalState = _React$useState2[1];
  var unit = props.unit,
    className = props.className,
    _props$value = props.value,
    value = _props$value === void 0 ? internalValue : _props$value,
    _props$onChange = props.onChange,
    _onChange = _props$onChange === void 0 ? setInternalState : _props$onChange,
    _onBlur = props.onBlur,
    _props$debounce = props.debounce,
    debounce = _props$debounce === void 0 ? 150 : _props$debounce,
    title = props.title,
    _props$disabled = props.disabled,
    disabled = _props$disabled === void 0 ? false : _props$disabled,
    readonly = props.readonly,
    embedded = props.embedded,
    _props$type = props.type,
    type = _props$type === void 0 ? 'text' : _props$type,
    placeholder = props.placeholder,
    placeholderClassName = props.placeholderClassName,
    preIcon = props.preIcon,
    postIcon = props.postIcon,
    label = props.label,
    mask = props.mask,
    useFormik = props.useFormik,
    onClick = props.onClick,
    ref = props.ref,
    settingsPage = props.settingsPage,
    standard = props.standard,
    withPostIcon = props.withPostIcon,
    pickerPopup = props.pickerPopup,
    filterPicker = props.filterPicker,
    labelClassName = props.labelClassName;

  // https://formik.org/docs/api/useField#fieldhookconfigvalue
  var meta;
  if (useFormik) {
    var _useField = useField(props);
    var _useField2 = _slicedToArray(_useField, 3);
    value = _useField2[0].value;
    meta = _useField2[1];
    var _useField2$ = _useField2[2];
    _onChange = _useField2$.setValue;
    _onBlur = _useField2$.setTouched;
  }
  return __jsx("div", {
    className: classNames(styles.root, (_classNames = {}, _defineProperty(_classNames, styles.settingsPage, settingsPage), _defineProperty(_classNames, styles.withPreIcon, !!preIcon), _defineProperty(_classNames, styles.withPostIcon, !!postIcon), _defineProperty(_classNames, styles.withLabel, !!label), _defineProperty(_classNames, styles.withValue, !!value), _defineProperty(_classNames, styles.embedded, !!embedded), _defineProperty(_classNames, styles.standard, !!standard), _defineProperty(_classNames, styles.pickerPopup, !!pickerPopup), _defineProperty(_classNames, styles.filterPicker, !!filterPicker), _classNames), className),
    "data-readonly": !!readonly,
    "data-mask": mask,
    "data-embedded": embedded,
    title: title || '',
    ref: ref,
    onClick: onClick
  }, preIcon && __jsx("div", {
    className: styles.preIconContainer
  }, preIcon), label && __jsx("div", {
    className: [styles.label, labelClassName].join(' ')
  }, label), placeholder && __jsx("div", {
    className: [standard ? styles.standardPlaceholder : styles.placeholder, placeholderClassName].join(' ')
  }, placeholder), postIcon && __jsx("div", {
    className: withPostIcon ? styles.customPostIconContainer : styles.postIconContainer
  }, __jsx("div", null, postIcon)), !!readonly && __jsx("div", {
    className: [styles.readonlyContainer, '!text-[color:var(--primary-color)]'].join(' '),
    "data-textfield": ""
  }, __jsx("span", {
    className: "!text-[color:var(--primary-color)]"
  }, value)) || !!debounce && __jsx(DebounceInput, {
    className: "!text-[color:var(--primary-color)]",
    autoComplete: "on",
    debounceTimeout: debounce,
    value: value || '',
    onChange: function onChange(e) {
      return _onChange && _onChange(e.target.value);
    },
    onBlur: function onBlur(e) {
      return _onBlur && _onBlur();
    },
    disabled: disabled,
    type: type,
    "data-textfield": ""
  }), __jsx("div", {
    className: "".concat(isArabic ? 'mr-auto ml-2' : 'ml-auto mr-2 ', " !text-[--primary-color]")
  }, unit));
};
export default PartFormTextfield;