import React from "react";
var __jsx = React.createElement;
import { useRouter } from 'next/router';
import PartFormSelect from '../Select';
import Styles from './Styles.module.scss';
import LanguageIcon from '../../assets/Miscellaneous/languageIcon.svg';
import { setSearchBarOpened } from '../../../pages/search/searchRedux/SearchIndex';
import { useDispatch } from 'react-redux';
var LanguageSwitcher = function LanguageSwitcher() {
  var router = useRouter();
  var currentLocale = router === null || router === void 0 ? void 0 : router.locale;
  var handleChangeLanguage = function handleChangeLanguage(locale) {
    if ((router === null || router === void 0 ? void 0 : router.locale) == locale) return;
    router.replace(router.pathname, router.asPath, {
      locale: locale,
      query: router === null || router === void 0 ? void 0 : router.query
    });
  };
  var dispatch = useDispatch();
  return __jsx("div", {
    onClick: function onClick() {
      dispatch(setSearchBarOpened(false));
    }
  }, __jsx(PartFormSelect, {
    startIcon: __jsx(LanguageIcon, null),
    languageSwitcher: true,
    value: currentLocale,
    textPath: "key",
    valuePath: "value",
    className: Styles.languageSelect,
    onChange: function onChange(lang) {
      return handleChangeLanguage(lang);
    },
    options: [{
      key: 'English',
      value: 'en'
    }, {
      key: '(العربية) Arabic',
      value: 'ar'
    }]
  }));
};
export default LanguageSwitcher;