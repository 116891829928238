import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
var __jsx = React.createElement;
import classnames from 'classnames';
import { useField } from 'formik';
import find from 'lodash/find';
import get from 'lodash/get';
import matchesProperty from 'lodash/matchesProperty';
import React from 'react';
import PartDropdown from '../Dropdown';
import TextField from '../Textfield';
import IconArrow from '../../assets/arrow.svg';
import IconArrowDown from '../../assets/datepicker/arrowDown.svg';
import EnStyles from './part-form-select.module.scss';
import ArStyles from './part-form-select.ar.module.scss';
import { userService } from '../../../services';
var PartFormSelect = function PartFormSelect(props) {
  var _classnames;
  var isArabic = userService.isArabicLang();
  var styles = isArabic ? ArStyles : EnStyles;
  var _React$useState = React.useState(),
    _React$useState2 = _slicedToArray(_React$useState, 2),
    internalValue = _React$useState2[0],
    setInternalState = _React$useState2[1];
  var options = props.options,
    _props$value = props.value,
    value = _props$value === void 0 ? internalValue : _props$value,
    _props$valuePath = props.valuePath,
    valuePath = _props$valuePath === void 0 ? 'value' : _props$valuePath,
    _props$textPath = props.textPath,
    textPath = _props$textPath === void 0 ? 'label' : _props$textPath,
    title = props.title,
    _props$onChange = props.onChange,
    onChange = _props$onChange === void 0 ? setInternalState : _props$onChange,
    onBlur = props.onBlur,
    className = props.className,
    label = props.label,
    startIcon = props.startIcon,
    children = props.children,
    readonly = props.readonly,
    disabled = props.disabled,
    useFormik = props.useFormik,
    noMaxHeight = props.noMaxHeight,
    placeholder = props.placeholder,
    filterComplianceButton = props.filterComplianceButton,
    settingsFilter = props.settingsFilter,
    languageSwitcher = props.languageSwitcher,
    settingsPage = props.settingsPage,
    withoutInputBorder = props.withoutInputBorder,
    labelClassName = props.labelClassName,
    toUp = props.toUp;
  var dropdownComponentRef = React.useRef(null);

  // https://formik.org/docs/api/useField#fieldhookconfigvalue
  var meta, setTouched;
  if (useFormik) {
    // !Important: When immidiate call setTouched after setValue - formik dont revalidate
    // !Important: setTimeout used because formik has race-condition
    var _useField = useField(props);
    var _useField2 = _slicedToArray(_useField, 3);
    value = _useField2[0].value;
    meta = _useField2[1];
    var _useField2$ = _useField2[2];
    onChange = _useField2$.setValue;
    setTouched = _useField2$.setTouched;
    onBlur = function onBlur() {
      return setTimeout(setTouched, 0);
    };
  }
  return __jsx(PartDropdown, {
    toUp: toUp,
    className: classnames(styles.root, (_classnames = {}, _defineProperty(_classnames, styles.readonly, readonly), _defineProperty(_classnames, styles.readonly, disabled), _classnames), className),
    ref: dropdownComponentRef,
    disabled: readonly || disabled,
    onClose: function onClose() {
      return onBlur && onBlur();
    }
  }, __jsx(TextField, {
    labelClassName: labelClassName,
    className: ['!text-[color:var(--primary-color)]', filterComplianceButton ? styles.filterComplianceButton : settingsFilter ? styles.settingsFilter : languageSwitcher ? styles.languageSwitcher : settingsPage ? styles.settingsPage : styles.DDOpenButton, withoutInputBorder && styles.noBorder],
    value: get(find(options, valuePath ? matchesProperty(valuePath, value) : value), textPath) || '',
    label: settingsFilter || languageSwitcher ? '' : label,
    embedded: true,
    readonly: true,
    placeholder: placeholder,
    preIcon: startIcon,
    postIcon: !readonly && (settingsFilter ? __jsx(IconArrowDown, {
      className: styles.ddIcon
    }) : __jsx(IconArrow
    // fill={settingsFilter ? '#E00D1D' : '#000000'}
    , {
      fill: 'var(--secondary-color)',
      className: styles.ddIcon
    })),
    title: title
  }), __jsx("div", {
    className: styles.DDContentContainer
  }, __jsx("div", {
    className: styles.DDTriangle
  }), __jsx("div", {
    className: styles.DDContent,
    style: noMaxHeight ? {
      maxHeight: 'unset'
    } : {}
  }, require('lodash/isEmpty')(options) && !children && __jsx("div", {
    className: styles.emptyText
  }, "No items"), options && (options === null || options === void 0 ? void 0 : options.map(function (option, index) {
    return __jsx("button", {
      type: "button",
      key: (options === null || options === void 0 ? void 0 : options.label) || index,
      className: classnames('text-[color:var(--primary-color)]', styles.DDItemButton, _defineProperty({}, styles.DDItemButtonSelected, (option === null || option === void 0 ? void 0 : option.value) === value)),
      onClick: function onClick() {
        onChange && onChange(valuePath ? get(option, valuePath) : option);
        dropdownComponentRef.current.close();
      }
    }, __jsx(TextField, {
      labelClassName: labelClassName,
      className: "!text-[color:var(--primary-color)]",
      value: get(option, textPath),
      readonly: true,
      embedded: true
    }));
  })), children)));
};
export default PartFormSelect;