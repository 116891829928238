import classnames from 'classnames';
import castArray from 'lodash/castArray';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';
import EnStyles from './part-dropdown.module.scss';
import ArStyles from './part-dropdown.ar.module.scss';
import { userService } from '../../../services';

interface PartDropdownProps {
    className?: string;
    children: React.ReactNode[];
    closeOnContentClick?: boolean;
    defaultOpened?: boolean;
    dontCloseOnOutsideClick?: boolean;
    dontCloseOnButtonClick?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    disabled?: boolean;
    toUp?: boolean;
    contentClassName?: string;
    page?: string;
}

interface PartDropdownRef {
    open: () => void;
    close: () => void;
}

const PartDropdown: React.ForwardRefRenderFunction<PartDropdownRef, PartDropdownProps> = (
    props,
    ref
) => {
    const {
        className,
        children,
        closeOnContentClick,
        defaultOpened = false,
        dontCloseOnOutsideClick,
        dontCloseOnButtonClick,
        onOpen,
        onClose,
        disabled,
        toUp,
        contentClassName,
        page,
    } = props;

    const [opened, setOpenState] = useState<boolean>(!!defaultOpened);
    const isArabic = userService.isArabicLang();
    const styles = isArabic ? ArStyles : EnStyles;

    const setOpen = (openState: boolean) => {
        if (openState && onOpen) onOpen();
        if (!openState && onClose) onClose();
        setOpenState(openState);
    };

    useImperativeHandle(ref, () => ({
        open: () => setOpen(true),
        close: () => setOpen(false),
    }));

    const handleDDButtonClick = () => {
        if (disabled) return;
        if (opened && dontCloseOnButtonClick) return;
        setOpen(!opened);
    };

    const rootNode = useRef<HTMLDivElement | null>(null);

    const handleOutsideClick = (e: MouseEvent) => {
        if (dontCloseOnOutsideClick) return;
        if (!opened) return;
        if (rootNode?.current?.contains(e.target as Node)) return;
        setOpen(false);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [opened]);

    const [DDButton, DDContent] = castArray(children);

    if (!DDButton) return null;

    return (
        <div
            className={classnames(styles.root, className)}
            ref={rootNode}
            dropdown-opened={opened ? '' : undefined}
            dropdown-disabled={disabled ? '' : undefined}>
            <button
                type='button'
                className={styles.DDOpenButton}
                onClick={handleDDButtonClick}
                dropdown-button=''
                dropdown-button-opened={opened ? '' : undefined}>
                {DDButton && DDButton}
            </button>
            {DDContent && (
                <div
                    className={[
                        page === 'maps'
                            ? styles.mapsDDContentContainer
                            : toUp
                            ? styles.toUpDDContentContainer
                            : styles.DDContentContainer,
                        contentClassName,
                    ].join(' ')}
                    dropdown-content=''
                    dropdown-content-opened={opened ? '' : undefined}
                    onClick={() => closeOnContentClick && setOpen(false)}>
                    {DDContent}
                </div>
            )}
        </div>
    );
};
export default React.forwardRef(PartDropdown);
